<template>
  <div class="newsSurvey" v-if="surveys.length">
    <vue-easy-lightbox ref="lightboxRef" moveDisabled rotateDisabled zoomDisabled :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"> </vue-easy-lightbox>
    <VueSlickCarouselc v-bind="settings" class="newsSurvey-carousel">
      <template #prevArrow="">
        <div class="custom-arrow">
          <iconic name="newArrowBack" />
        </div>
      </template>
      <div class="newsSurvey-carousel-slider" v-for="(survey, i) in howManyImages" :key="i">
        <h2 class="newsSurvey-title">{{ `Resultados ${survey.title}` }}</h2>
        <h2 class="newsSurvey-subtitle">
          {{
            `Top ${survey.responsesWithData.length}${i === 0 ? "" : ":"}
                  ${survey.question}`
          }}
        </h2>
        <div class="newsSurvey-slider-content first-elem-mobil">
          <div v-if="survey.responsesWithData.length > 3">
            <div class="newsSurvey-content-media" @click="showSingle(survey.responsesWithData[0].text, survey.responsesWithData[0].img)">
              <img :src="survey.responsesWithData[0].img" :alt="survey.responsesWithData[0].answer" />
              <p class="newSurvey-media-answer">{{ survey.responsesWithData[0].answer }}</p>
              <p class="newSurvey-media-rank">{{ 1 }}</p>
            </div>
            <div class="newsSurvey-content-media_mobile">
              <p class="newSurvey-media-rank_mobile">{{ 1 }}</p>
            </div>
          </div>
        </div>
        <div class="newsSurvey-slider-content">
          <div v-for="(media, j) in survey.responsesWithData" :key="j">
            <div v-if="validateHeaderdurvey(j, survey.responsesWithData.length)">
              <div class="newsSurvey-content-media" @click="showSingle(media.text, media.img)">
                <img :src="media.img" :alt="media.answer" />
                <p class="newSurvey-media-answer">{{ media.answer }}</p>
                <p class="newSurvey-media-rank">{{ j + 1 }}</p>
              </div>
              <div class="newsSurvey-content-media_mobile">
                <p class="newSurvey-media-rank_mobile">{{ j + 1 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #nextArrow="">
        <div class="custom-arrow">
          <iconic name="newArrowNext" />
        </div>
      </template>
    </VueSlickCarouselc>
  </div>
</template>

<script>
  import { socket } from "@/socket";
  import VueEasyLightbox from "vue-easy-lightbox";

  export default {
    components: {
      VueSlickCarouselc: () => import("vue-slick-carousel"),
      VueEasyLightbox,
    },
    data: function() {
      return {
        surveyTitle: "",
        surveys: [],
        surveysArr: [],
        visible: false,
        imgs: "",
        index: 0,
      };
    },
    methods: {
      showSingle(label, data) {
        this.imgs = [{ title: label, src: data }];
        this.show();
      },
      show() {
        this.visible = true;
      },
      handleHide() {
        this.visible = false;
      },
      getData: async function() {
        const _host = `poll/positions`;
        const { data } = await this.$axios.get(_host);
        data.results.map((it) => {
          it.totals.map((it2) => {
            it2.title = it.pollInProgress.title;
            this.surveysArr.push(it2);
          });
        });
        this.surveyTitle = data.results.length ? data.results[0].pollInProgress?.title : "";
        this.surveys = this.surveysArr;
      },
      updateSurveys(payload) {
        if (!payload?.answers?.length) {
          return;
        }
        const mapAnswer = {};

        payload.answers.forEach(({ questionId, optionId }) => (mapAnswer[optionId] = questionId));

        this.surveys.forEach((question) => {
          question.responsesWithData.forEach((response) => {
            if (mapAnswer[response._id]) {
              response.votes++;
            }
          });

          question.responsesWithData.sort((a, b) => b.votes - a.votes);
        });
      },
    },
    computed: {
      validateHeaderdurvey() {
        return function(j, countSurveys) {
          return (j === 0 && this.$screen.width > 768) || j !== 0 || countSurveys < 3;
        };
      },
      settings() {
        const breakpoints = [
          { breakpoint: 370, slidesToShow: 1 },
          { breakpoint: 495, slidesToShow: 1 },
          { breakpoint: 620, slidesToShow: 1 },
          { breakpoint: 860, slidesToShow: 1 },
          { breakpoint: 880, slidesToShow: 1 },
          { breakpoint: 1160, slidesToShow: 1 },
          { breakpoint: 1450, slidesToShow: 1 },
          { breakpoint: 1500, slidesToShow: 1 },
          { breakpoint: 1600, slidesToShow: 1 },
        ];

        const responsive = breakpoints.map(({ breakpoint, slidesToShow }) => {
          return { breakpoint, settings: { arrows: true, slidesToShow } };
        });

        return {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          touchThreshold: 5,
          responsive,
        };
      },
      howManyImages: function() {
        const questions = this.surveys;

        return questions.map((answer, idxQuest) => {
          const showdata = answer.responsesWithData.filter((response, idx) => (idxQuest === 0 ? idx < 5 : idx < 3));
          return { ...answer, responsesWithData: showdata };
        });
      },
    },
    async mounted() {
      await this.getData();
      if (socket) {
        socket.on("newPollVote", (test) => this.updateSurveys(test));
      }
    },
  };
</script>
<style lang="scss">
  .newsSurvey {
    grid-column-start: 1;
    grid-column-end: 5;
    width: 100%;
    padding: 20px 0;
    background: linear-gradient(to right, rgba(#ff1a4b, 0.3) -10%, rgba(255, 255, 255, 0.7) 40%);
    border-radius: 12px;
    box-shadow: rgb(216, 216, 216) 0px 2px 5px 0px;
    overflow: hidden;
    transition: 0.5s all;
    h2 {
      text-align: center;
      color: #000;
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 13px;
    }
    h2.newsSurvey-title {
      color: $primary_color;
    }

    .toolbar-btn.toolbar-btn__resize {
      display: none;
    }
    .vel-img {
      border-radius: 10px;
    }
    .vel-img-title {
      color: #ffffff !important;
      opacity: 1 !important;
      text-transform: uppercase;
      font-size: 25px !important;
      font-weight: 400 !important;
      font-family: BebasKai;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    }

    &-carousel {
      .newsSurvey-slider-content.first-elem-mobil {
        @media (min-width: $tablet_width) {
          display: none;
        }
      }

      .newsSurvey-slider-content {
        display: flex;
        justify-content: center;
        gap: 7px;

        @media (min-width: $tablet_width) {
          gap: 20px;
        }

        .newsSurvey-content-media_mobile {
          display: flex;
          justify-content: center;
          margin-top: 5px;

          @media (min-width: $tablet_width) {
            display: none;
          }

          .newSurvey-media-rank_mobile {
            position: relative;
            height: 20px;
            width: 20px;
            border-radius: 50px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            font-family: "bebasKai";
            font-weight: 400;
            background: $primary_color;
          }
        }

        .newsSurvey-content-media {
          position: relative;
          border-radius: 50%;
          width: 80px;
          height: 80px;
          box-shadow: 0px 1px 5px 0px #898787;

          @media (min-width: $tablet_width) {
            border-radius: 12px;
            width: 150px;
            height: 125px;
            width: 150px;
          }

          overflow: hidden;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
          }

          &:hover {
            img {
              transform: scale(1.2);
            }
          }

          .newSurvey-media-rank {
            position: absolute;
            top: 5px;
            right: 5px;
            height: 20px;
            width: 20px;
            border-radius: 50px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            font-family: "bebasKai";
            font-weight: 400;
            background: $primary_color;
            display: none;

            @media (min-width: $tablet_width) {
              display: block;
            }
          }

          .newSurvey-media-answer {
            position: absolute;
            bottom: 6px;
            z-index: 20;
            width: 100%;
            text-align: center;
            font-weight: 600;
            color: #fff;
            font-size: 12px;
            text-transform: uppercase;
            font-family: "bebasKai";
            font-weight: 400;
          }

          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            transition: transform 0.3s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    .slick-arrow {
      top: 50%;
      display: flex;
      align-items: center;
      transition: opacity 0.3s ease;

      &.hide {
        opacity: 0;
      }
    }

    .slick-prev,
    .slick-next {
      height: 100%;
      width: 27px;
    }

    .slick-prev::before,
    .slick-next::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .slick-prev {
      left: 2px;
      height: 100px;

      @media (min-width: $tablet_width) {
        left: 30px;
      }
    }

    .slick-next {
      right: 2px;
      height: 100px;

      @media (min-width: $tablet_width) {
        right: 43px;
      }
    }

    .custom-arrow {
      margin: auto;

      .iconic {
        font-size: 40px;
        text-align: center;
        color: rgb(49, 49, 49);

        .arrow {
          color: rgb(49, 49, 49);
        }
      }
    }
  }

  @media screen and (max-width: 1730px) {
    .newsSurvey {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
</style>
